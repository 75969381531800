import { h } from 'preact';
import { Link } from 'preact-router/match';
import { useEffect, useState } from 'preact/hooks';
import style from './style.css';
import classNames from 'classnames';

const Header = () => {
	const [hasScrolled, setHasScrolled] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);


	useEffect(() => {
		const onScroll = e => {
			setHasScrolled(e.target.documentElement.scrollTop > 10);
		  };
		  window.addEventListener("scroll", onScroll);
		  return () => window.removeEventListener("scroll", onScroll);
	}, [])

	return (
		<header class={classNames(`flex-center ${style.header}`, {
			[style.headerSelected]: hasScrolled 
		})}>
			<div className="flex container">
				<div className="mr-auto logo">
					<img src="/assets/logo.svg"></img>
				</div>
				

				<div className={classNames('flex', style.linksContainer,  { [style.open]: menuOpen })}>
					<Link activeClassName={style.active} href="/">Home</Link>
					{/* <Link activeClassName={style.active} href="/">Donate</Link> */}
					<Link activeClassName={style.active} href="/glioblastoma-info">Glioblastoma Info</Link>
					<Link activeClassName={style.active} href="/about">About Us</Link>
				</div>

				<img
					className={classNames(style.hamburger)}
					src="/assets/images/hamburger.svg"
					onClick={() => setMenuOpen(!menuOpen)}
				></img>

				{/* <Link activeClassName={style.active} href="/profile">Me</Link>
				<Link activeClassName={style.active} href="/profile/john">John</Link> */}
			</div>
		</header>
	);
};

export default Header;
